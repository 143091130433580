import React from 'react';

const IconMobile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 350 500">
    <title>+91 (883) 906-7903</title>
    <path
      fill="currentColor"
      d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"></path>
  </svg>
);

export default IconMobile;
