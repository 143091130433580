module.exports = {
  siteTitle: 'Shubham Gupta',
  siteDescription:
    'Shubham Gupta is a junior at IIT Roorkee, who is specialized in building exceptional websites, applications, and everything in between.',
  siteKeywords:
    'Shubham Gupta, Shubham, shadow, shubhamgupta2956, wryonik, developer, software developer, front-end engineer, web developer, javascript, IIT Roorkee',
  siteUrl: 'https://wryonik.github.io/',
  siteLanguage: 'en_US',
  name: 'Shubham Gupta',
  location: 'IIT Roorkee, India',
  email: 'shubham.gupta2956@gmail.com',
  github: 'https://github.com/wryonik',
  twitterHandle: '@sgupta2956',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/wryonik',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/shubhamgupta2956',
    },
    // {
    //   name: 'Facebook',
    //   url: 'https://www.facebook.com/shubham.gupta2956',
    // },
    // {
    //   name: 'Instagram',
    //   url: 'https://www.instagram.com/wryonik',
    // },
    {
      name: 'Twitter',
      url: 'https://twitter.com/wryonik',
    },
    // {
    //   name: 'Mobile',
    // },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Projects',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
