import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <g transform="translate(25.000000, 25.000000)">
          <path
            d="M13.7363 21.8877C9.39453 20.6396 6.23047 19.1104 4.24414 17.2998C2.27539 15.4717 1.29102 13.2217 1.29102 10.5498C1.29102 7.52637 2.49512 5.03027 4.90332 3.06152C7.3291 1.0752 10.4756 0.0820312 14.3428 0.0820312C16.9795 0.0820312 19.3262 0.591797 21.3828 1.61133C23.457 2.63086 25.0566 4.03711 26.1816 5.83008C27.3242 7.62305 27.8955 9.58301 27.8955 11.71H22.8066C22.8066 9.38965 22.0684 7.57031 20.5918 6.25195C19.1152 4.91602 17.0322 4.24805 14.3428 4.24805C11.8467 4.24805 9.89551 4.80176 8.48926 5.90918C7.10059 6.99902 6.40625 8.51953 6.40625 10.4707C6.40625 12.0352 7.06543 13.3623 8.38379 14.4521C9.71973 15.5244 11.9785 16.5088 15.1602 17.4053C18.3594 18.3018 20.8555 19.2949 22.6484 20.3848C24.459 21.457 25.7949 22.7139 26.6562 24.1553C27.5352 25.5967 27.9746 27.293 27.9746 29.2441C27.9746 32.3555 26.7617 34.8516 24.3359 36.7324C21.9102 38.5957 18.667 39.5273 14.6064 39.5273C11.9697 39.5273 9.50879 39.0264 7.22363 38.0244C4.93848 37.0049 3.17188 35.6162 1.92383 33.8584C0.693359 32.1006 0.078125 30.1055 0.078125 27.873H5.16699C5.16699 30.1934 6.01953 32.0303 7.72461 33.3838C9.44727 34.7197 11.7412 35.3877 14.6064 35.3877C17.2783 35.3877 19.3262 34.8428 20.75 33.7529C22.1738 32.6631 22.8857 31.1777 22.8857 29.2969C22.8857 27.416 22.2266 25.9658 20.9082 24.9463C19.5898 23.9092 17.1992 22.8896 13.7363 21.8877Z"
            fill="currentColor"
          />
        </g>
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
